import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbWithPageTitle } from "../../components/breadcrumbButton";
import { I18n, Translate } from "react-redux-i18n";
import FlexSpace from "../../components/FlexSpace";
import { Button, Form, message, Progress, Select, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_SETTINGS_TARIFF_PAGE } from "../../utils/paths";
import { TimezonesList, useTimezone } from "../../utils/timezones";
import { useActions } from "../../hooks/useActions";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { AccountSettingsItemLayout, Box, BoxButtons, BoxContent } from "./layout";
import PasswordChange from "../../components/header/passwordChange";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAccessTokenObj } from "../../utils/jwt";
import InputFormItemComponent from "../../components/form/items/input";
import { UserExtendedData } from "../../types/userType";
import { LimitsEnum, SubscriptionInfoStatusEnum } from "../../types/subscriptionInfo";
import dayjs from "dayjs";
import { useRequests } from "../../hooks/useRequests";
import PaymentHistoryModal from "./paymentHistory";
import { BasicPlanId } from "./helpers";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const TARIFFS_DATE_TIME_FORMAT = "D MMMM YYYY";

const AccountSettingsPage: FC = () => {
  const { getUserInformation } = useRequests();

  useEffect(() => {
    getUserInformation().catch(console.log);
  }, []);

  return (
    <div style={{ minHeight: "60vh" }}>
      <BreadcrumbWithPageTitle type={"text"} pageTitle={I18n.t("myAccount")} />
      <FlexSpace mt={24} alignItems={"baseline"}>
        <TariffPlanComponent />
        <CurrentLimitsInfoComponent />
        <UserInfoComponent />
        <TimezoneComponent />
      </FlexSpace>
    </div>
  );
};

export default AccountSettingsPage;

const TimezoneComponent = () => {
  const tzState = useTimezone();
  const [tz, setTz] = useState<string | undefined>();
  const { updateUserTimezone } = useActions();
  const [isEditing, setEditing] = useState(false);

  const onSave = useCallback(() => {
    if (tz) {
      tzState.onTimezoneChange(tz);
      updateUserTimezone(tz);
    }
    setEditing(false);
  }, [tzState, tz]);

  const onCancel = useCallback(() => {
    setEditing(false);
    setTz(undefined);
  }, [setEditing, setTz]);

  return (
    <Box
      onChangeClick={() => setEditing(true)}
      title={I18n.t("currentTimezone")}
      buttonNode={
        isEditing ? (
          <div style={{ display: "flex", gap: 5 }}>
            <Tooltip title={I18n.t("cancel")} mouseEnterDelay={1}>
              <Button icon={<CloseOutlined />} onClick={onCancel} />
            </Tooltip>
            <Tooltip title={I18n.t("save")} mouseEnterDelay={1}>
              <Button icon={<CheckOutlined />} onClick={onSave} />
            </Tooltip>
          </div>
        ) : undefined
      }
    >
      <div style={{ display: "grid" }}>
        {isEditing ? (
          <Select
            showSearch
            onChange={setTz}
            style={{ width: 285 }}
            options={TimezonesList}
            value={tz ?? tzState.label}
            placeholder={I18n.t("selectTimezone")}
          />
        ) : (
          <>
            <span style={{ fontSize: 16, fontWeight: 500 }}>{tzState?.utc ?? I18n.t("notChosen")}</span>
            <span style={{ color: "gray" }}>{tzState?.area ?? I18n.t("notChosen")}</span>
          </>
        )}
      </div>
    </Box>
  );
};

const TariffPlanComponent = () => {
  const navigate = useNavigate();
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  const { cancelCurrentSubscription } = useRequests();
  const { appLang, subscriptionInfo } = useSelector((state: RootState) => ({
    subscriptionInfo: state.users.subscriptionInfo,
    appLang: state.persisted.appLanguage,
  }));

  const getEndDate = useCallback(() => {
    const endDate$ = subscriptionInfo?.subscription.endDate;

    if (endDate$) {
      return dayjs(endDate$).format(TARIFFS_DATE_TIME_FORMAT);
    }

    return "N/A";
  }, [appLang, subscriptionInfo?.subscriptionPlan.limits]);

  const isTrialActive = useCallback(() => {
    const subscription = subscriptionInfo?.subscription;
    return Boolean(
      subscription?.status === SubscriptionInfoStatusEnum.TRIAL &&
        subscription.trialEndDate &&
        dayjs(subscription.trialEndDate).isAfter(dayjs()),
    );
  }, [subscriptionInfo?.subscription]);

  const getCurrentTariff = useCallback(() => {
    const subscription = subscriptionInfo?.subscription;
    const subscriptionPlan = subscriptionInfo?.subscriptionPlan;
    const nexPaymentLabel = I18n.t("tariffs.nextPayment").concat(" ", getEndDate());
    const trialActive = isTrialActive();

    const trialStartDate = dayjs(subscription?.trialStartDate).format(TARIFFS_DATE_TIME_FORMAT);
    const trialEndDate = dayjs(subscription?.trialEndDate).format(TARIFFS_DATE_TIME_FORMAT);

    const msg = I18n.t("tariffs.trialPeriodValidFromTo");
    const trialPeriodLabel = msg.replace("{startDate}", trialStartDate).replace("{endDate}", trialEndDate);

    return {
      name: subscriptionPlan?.name ?? "N/A",
      description: trialActive ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 3, marginTop: 10 }}>
          <span>{trialPeriodLabel}</span>
          <span>{nexPaymentLabel}</span>
        </div>
      ) : (
        nexPaymentLabel
      ),
    };
  }, [isTrialActive, subscriptionInfo, getEndDate]);

  const onChangeClick = () => navigate(ACCOUNT_SETTINGS_TARIFF_PAGE);

  const onCancelTariff = useCallback(() => {
    setCancellingSubscription(true);
    cancelCurrentSubscription()
      .then(() => {
        if (subscriptionInfo?.subscription.endDate) {
          const endDate = dayjs(subscriptionInfo.subscription.endDate).format("D MMMM YYYY");
          const msg = I18n.t("tariffs.tariffCanceledSuccessText");
          message.success(msg.replace("{dateValue}", endDate)).then(() => {});
        }
        setCancellingSubscription(false);
      })
      .catch(() => setCancellingSubscription(false));
  }, [subscriptionInfo, cancelCurrentSubscription, setCancellingSubscription]);

  const handlePaymentHistoryOpen = useCallback(() => {
    setHistoryModalOpen(true);
  }, [setHistoryModalOpen]);

  const handlePaymentHistoryClose = useCallback(() => {
    setHistoryModalOpen(false);
  }, [setHistoryModalOpen]);

  return (
    <Box
      title={I18n.t("currentTariff")}
      alignItems={"baseline"}
      additionalContent={
        <div style={{ padding: "0 30px", marginTop: 48 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <Translate value={"tariffs.paymentMethod"} />:
            </span>
            <b style={{ fontSize: 16 }}>
              {(subscriptionInfo?.subscriptionPlan.price ?? 0).toLocaleString("ru-RU")}{" "}
              {subscriptionInfo?.subscriptionPlan.currency ?? "N/A"}
            </b>
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <span>
              {subscriptionInfo?.subscription?.paymentProps?.cardType ?? "N/A"} **
              {subscriptionInfo?.subscription?.paymentProps?.cardLastFour ?? "N/A"}
            </span>
            <BoxButtons
              // button1={{ label: I18n.t("tariffs.chooseAnotherCard"), onClick: () => {}, type: "default" }}
              button2={{ label: I18n.t("tariffs.paymentHistory"), onClick: handlePaymentHistoryOpen }}
            />
          </div>
        </div>
      }
      buttonNode={
        <BoxButtons
          button1={{ label: I18n.t("tariffs.changeTheTariff"), onClick: onChangeClick }}
          button2={{
            danger: true,
            loading: cancellingSubscription,
            label: I18n.t("tariffs.cancelTheTariff"),
            confirm: {
              text: I18n.t("tariffs.cancelConfirmationText"),
              action: onCancelTariff,
            },
          }}
        />
      }
    >
      <div style={{ display: "grid" }}>
        <span style={{ fontSize: 16, fontWeight: 500 }}>{getCurrentTariff().name}</span>
        <span style={{ color: "gray" }}>{getCurrentTariff().description}</span>
      </div>
      <PaymentHistoryModal open={historyModalOpen} onClose={handlePaymentHistoryClose} />
    </Box>
  );
};

const UserInfoComponent = () => {
  const tokenObj = useAccessTokenObj();
  const { user } = useSelector((state: RootState) => ({
    user: state.users.userExtendedData,
  }));
  const { updateUserInfo } = useActions();
  const [isEditing, setEditing] = useState(false);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const [form] = Form.useForm<Pick<UserExtendedData, "firstName" | "lastName">>();

  const onSave = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        updateUserInfo(values);
        setEditing(false);
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form, updateUserInfo, setEditing]);

  const onCancel = useCallback(() => {
    setEditing(false);
    form.resetFields();
  }, [setEditing, form]);

  const fullName = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return user.firstName?.concat(" ", user.lastName);
    }
    return "N/A";
  }, [user]);

  return (
    <AccountSettingsItemLayout title={I18n.t("tariffs.userInformation")}>
      {isEditing ? (
        <div style={{ display: "flex", padding: "0 30px", gap: 10 }}>
          <Form
            form={form}
            layout={"vertical"}
            style={{ flex: 1, position: "relative", top: -27.5 }}
            initialValues={{ firstName: user?.firstName, lastName: user?.lastName }}
          >
            <InputFormItemComponent required name={"firstName"} label={I18n.t("firstname")} />
            <InputFormItemComponent required name={"lastName"} label={I18n.t("lastname")} />
          </Form>
          <div style={{ display: "flex", flexDirection: "column", gap: 5, width: 140 }}>
            <Button children={<Translate value={"cancel"} />} onClick={onCancel} />
            <Button type={"primary"} children={<Translate value={"save"} />} onClick={onSave} />
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", padding: "0 30px" }}>
          <BoxContent
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
            description={tokenObj?.email ?? "N/A"}
            title={fullName}
          />
          <BoxButtons
            button1={{ label: I18n.t("change"), onClick: () => setEditing(true) }}
            button2={{ label: I18n.t("changePassword"), onClick: () => setPasswordChangeModalOpen(true) }}
          />
        </div>
      )}
      <PasswordChange className={"none"} show={passwordChangeModalOpen} setShow={setPasswordChangeModalOpen} />
    </AccountSettingsItemLayout>
  );
};

const CurrentLimitsInfoComponent = () => {
  const { appLang, subscriptionInfo } = useSelector((state: RootState) => ({
    subscriptionInfo: state.users.subscriptionInfo,
    appLang: state.persisted.appLanguage,
  }));

  const LimitItem = (args: { label: string; currentValue: number; maxValue: number }) => {
    const calculateAvailablePercentage = (): number => {
      if (maxValue === 0) return 0;
      return Math.ceil((currentValue / maxValue) * 100);
    };
    const { label, currentValue, maxValue } = args;
    const percent = calculateAvailablePercentage();

    return (
      <div>
        <span>{label}</span>
        <Progress
          percent={percent}
          format={() => `${currentValue}/${maxValue}`}
          status={"normal"}
          percentPosition={{ align: "end", type: "outer" }}
        />
      </div>
    );
  };

  const getMaxValue = useCallback(
    (key: LimitsEnum) => {
      return subscriptionInfo?.subscriptionPlan.limits[key] ?? 0;
    },
    [subscriptionInfo?.subscriptionPlan.limits],
  );

  const getAvailableValue = useCallback(
    (key: LimitsEnum) => {
      return subscriptionInfo?.usage[key] ?? 0;
    },
    [subscriptionInfo?.usage],
  );

  const getEndDate = useCallback(() => {
    const endDate$ = subscriptionInfo?.subscription.endDate;

    if (endDate$) {
      return dayjs(endDate$).format(TARIFFS_DATE_TIME_FORMAT);
    }

    return "N/A";
  }, [appLang, subscriptionInfo?.subscriptionPlan.limits]);

  return (
    <Box
      title={I18n.t("tariffs.currentLimits")}
      buttonNode={<div style={{ display: "none" }}></div>}
      additionalContent={
        <div style={{ padding: "0 30px" }}>
          <hr />
          <div style={{ margin: 15, lineHeight: 1.5, marginRight: 0 }}>
            <LimitItem
              currentValue={getAvailableValue(LimitsEnum.SURVEYS)}
              maxValue={getMaxValue(LimitsEnum.SURVEYS)}
              label={I18n.t("tariffs.numberOfSurveys") + ":"}
            />
            <LimitItem
              currentValue={getAvailableValue(LimitsEnum.SURVEY_INSTANCES)}
              maxValue={getMaxValue(LimitsEnum.SURVEY_INSTANCES)}
              label={I18n.t("tariffs.numberOfReviews") + ":"}
            />
            {/*<LimitItem*/}
            {/*  currentValue={getAvailableValue(LimitsEnum.EXTRA_USERS)}*/}
            {/*  maxValue={getMaxValue(LimitsEnum.EXTRA_USERS)}*/}
            {/*  label={I18n.t("tariffs.numberOfUsers") + ":"}*/}
            {/*/>*/}
            {subscriptionInfo?.subscriptionPlan?.planId !== BasicPlanId && (
              <>
                <LimitItem
                  currentValue={getAvailableValue(LimitsEnum.CONTACTS)}
                  maxValue={getMaxValue(LimitsEnum.CONTACTS)}
                  label={I18n.t("tariffs.numberOfContacts") + ":"}
                />
                <LimitItem
                  currentValue={getAvailableValue(LimitsEnum.API_CALLS)}
                  maxValue={getMaxValue(LimitsEnum.API_CALLS)}
                  label={I18n.t("tariffs.numberOfAPICalls") + ":"}
                />
              </>
            )}
          </div>
          <hr />
          <div style={{ display: "flex", gap: 10, marginTop: 24 }}>
            <span>
              <Translate value={"tariffs.limitWillBeUpdated"} />
            </span>
            <b>{getEndDate()}</b>
          </div>
        </div>
      }
      children={null}
    />
  );
};
