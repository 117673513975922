import "./index.scss";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbWithPageTitle } from "../../components/breadcrumbButton";
import { I18n, Translate } from "react-redux-i18n";
import { generateTariffs } from "./helpers";
import FlexSpace from "../../components/FlexSpace";
import { TariffType } from "../../types/tariff";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAccessTokenObj } from "../../utils/jwt";
import { Language } from "../../types/language";
import { useRequests } from "../../hooks/useRequests";
import dayjs from "dayjs";
import { SubscriptionInfoStatusEnum } from "../../types/subscriptionInfo";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const TariffPage: FC = () => {
  const [tariffs, setTariffs] = useState<TariffType[]>([]);
  const { appLang, planInfo } = useSelector((state: RootState) => ({
    appLang: state.persisted.appLanguage,
    planInfo: state.users.subscriptionInfo?.subscriptionPlan,
    subscription: state.users.subscriptionInfo?.subscription,
  }));

  useEffect(() => {
    const newTariffs = generateTariffs(planInfo?.planId);
    setTariffs(newTariffs);
  }, [appLang, planInfo]);

  return (
    <div style={{ minHeight: "70vh" }}>
      <BreadcrumbWithPageTitle type={"text"} pageTitle={I18n.t("tariffPlans")} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 24,
          flexDirection: "column",
          gap: 24,
        }}
      >
        <h1>
          <Translate value={"tariffPlans"} />
        </h1>
        <h3 style={{ margin: 0, fontWeight: 400, color: "#3A3A3A" }}>
          <Translate value={"selectThePlanThatSuitsYouBest"} />
        </h3>
      </div>
      <FlexSpace mt={64} gap={70} position={"center"} alignItems={"baseline"}>
        {tariffs.map((tariff) => (
          <TariffCard tariff={tariff} key={tariff.tariffId} />
        ))}
      </FlexSpace>
    </div>
  );
};

export default TariffPage;

enum PurchaseState {
  NONE,
  IN_PROGRESS,
  SUCCESSFULLY,
  ERROR,
}

type TariffCardProps = {
  readonly tariff: TariffType;
};

const TariffCard: FC<TariffCardProps> = ({ tariff }) => {
  const pricesUrl = window._env_.TARIFF_PRICES_URL ?? "";
  const userObj = useAccessTokenObj();
  const subscriptionInfo = useSelector((state: RootState) => state.users.subscriptionInfo);
  const { getUserInformation, buySubscriptionPlan } = useRequests();
  const [purchaseStatus, setPurchaseStatus] = useState<PurchaseState>(PurchaseState.NONE);
  const language = useSelector((state: RootState) => {
    const lang = state.persisted.appLanguage;
    switch (lang) {
      case Language.KK:
        return "kk-KZ";
      case Language.EN:
        return "en-US";
      case Language.RU:
      default:
        return "kk";
    }
  });

  const isPlanActive = useMemo(() => {
    return Boolean(
      subscriptionInfo?.subscription?.status === SubscriptionInfoStatusEnum.ACTIVE ||
        (subscriptionInfo?.subscription.status === SubscriptionInfoStatusEnum.TRIAL &&
          subscriptionInfo?.subscription.trialEndDate &&
          dayjs(subscriptionInfo.subscription.trialEndDate).isAfter(dayjs())),
    );
  }, [subscriptionInfo]);

  const handleChoose = useCallback(async () => {
    if ((tariff.isCurrent && isPlanActive) || !userObj?.sub || !subscriptionInfo) {
      return;
    }

    try {
      setPurchaseStatus(PurchaseState.IN_PROGRESS);
      const subscriptionId$ = subscriptionInfo.subscription.id;
      const planInfo = await buySubscriptionPlan(tariff.tariffId, userObj.sub, subscriptionId$);

      await openPaymentWidget({
        language: language,
        userId: planInfo.userId,
        invoiceId: planInfo.orderId,
        planId: planInfo.planId,
        currency: planInfo.currency,
        amount: planInfo.totalAmount,
        subscriptionId: subscriptionId$,
        tariffName: tariff.name,
      });
      await getUserInformation();
      setPurchaseStatus(PurchaseState.SUCCESSFULLY);
    } catch (e) {
      setPurchaseStatus(PurchaseState.ERROR);
    }
  }, [userObj, subscriptionInfo, tariff.tariffId, isPlanActive, language, setPurchaseStatus, getUserInformation]);

  return (
    <div
      className={`tariff-card unselectable ${!tariff.isCurrent ? tariff.name : ""}`}
      style={{ borderRadius: 10, width: 399, padding: 30 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 30,
            padding: "0 30px",
            justifyContent: "center",
            textAlign: "center",
            marginTop: 10,
          }}
        >
          <span style={{ fontSize: 33 }}>{tariff.name}</span>
          <span style={{ fontSize: 16 }}>{tariff.description}</span>
          <div>
            <span style={{ fontSize: 30 }}>{tariff.amount}</span>
            <span style={{ color: "#3A3A3A" }}>
              {" / "}
              <Translate value={"month"} />
            </span>
          </div>
        </div>
        <div style={{ margin: "30px 0" }}>
          {tariff.options.map((opt, key) => (
            <div
              key={key}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 18,
                justifyContent: "center",
                width: "100%",
                padding: "5px 30px",
              }}
            >
              <div style={{ display: "flex", gap: 5 }}>
                <img style={{ width: 25, height: 15 }} src={`/assets/icons/bullet.svg`} alt={String(key)} />
                <span style={{ fontSize: 17 }}>{opt}</span>
              </div>
            </div>
          ))}
        </div>
        {/*<div*/}
        {/*  style={{*/}
        {/*    marginBottom: 30,*/}
        {/*    fontSize: 16,*/}
        {/*    color: "#979797",*/}
        {/*    padding: "0 30px",*/}
        {/*    display: "flex",*/}
        {/*    flexDirection: "column",*/}
        {/*    gap: 5,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <span>*/}
        {/*    <Translate value={"additional"} />:*/}
        {/*  </span>*/}
        {/*  <div style={{ display: "flex", flexDirection: "column" }}>*/}
        {/*    {tariff.additional?.map((additionalItem, additionalItemIdx) => (*/}
        {/*      <span key={additionalItemIdx}>{additionalItem}</span>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*  <span>{}</span>*/}
        {/*</div>*/}
        <div
          style={{
            gap: 10,
            width: "100%",
            display: "flex",
            padding: "0 20px",
            flexDirection: "column",
            marginBottom: 30,
          }}
        >
          <Button
            type={"primary"}
            style={{ flex: 1 }}
            onClick={handleChoose}
            disabled={isPlanActive && tariff.isCurrent}
            loading={purchaseStatus === PurchaseState.IN_PROGRESS}
          >
            <Translate value={isPlanActive && tariff.isCurrent ? "currentPlan" : "buy"} />
          </Button>
          <Button type={"default"} onClick={() => window.open(pricesUrl, "_blank")}>
            <Translate value={"moreDetails"} />
          </Button>
        </div>
      </div>
    </div>
  );
};

// type PaymentMethodContentProps = {
//   open: boolean;
//   close: () => void;
// } & Pick<TariffType, "tariffId">;

// const defaultMethod = PaymentMethodsList[0].value;

// const PaymentMethodsModal = ({ tariffId, open, close }: PaymentMethodContentProps) => {
//   const [selectedMethod, setSelectedMethod] = useState<string>(defaultMethod);
//   const navigate = useNavigate();
//
//   const onNext = useCallback(() => {
//     navigate(`${ACCOUNT_SETTINGS_TARIFF_PAGE}/${tariffId}/${selectedMethod}`);
//     close();
//   }, [tariffId, selectedMethod, close]);
//
//   const onCancel = useCallback(() => {
//     if (selectedMethod !== defaultMethod) {
//       setSelectedMethod(defaultMethod);
//     }
//     close();
//   }, [selectedMethod]);
//
//   return (
//     <Modal
//       centered
//       open={open}
//       width={300}
//       closable={false}
//       okButtonProps={{ hidden: true }}
//       cancelButtonProps={{ hidden: true }}
//       footer={<FooterActions onCancel={onCancel} onSubmit={onNext} submitLabelTranslationKey={"next"} />}
//     >
//       <div style={{ width: "100%", padding: 10 }}>
//         <div style={{ marginBottom: 24 }}>
//           <Translate value={"selectPaymentMethod"} />:
//         </div>
//         <Radio.Group
//           value={selectedMethod}
//           options={PaymentMethodsList}
//           style={{ display: "grid", marginBottom: 24, gridGap: 5 }}
//           onChange={(v) => setSelectedMethod(v.target.value)}
//         />
//       </div>
//     </Modal>
//   );
// };

type PaymentWidgetProps = {
  readonly userId: string;
  readonly invoiceId: string;
  readonly planId: string;
  readonly subscriptionId: string;
  readonly tariffName: string;
  readonly amount: number;
  readonly currency: string;
  readonly language: "kk" | "kk-KZ" | "en-US";
};

const openPaymentWidget = async (props: PaymentWidgetProps) => {
  const PublicId = window._env_.CP_PUBLIC_ID;

  const { userId, invoiceId, currency, planId, subscriptionId, tariffName, amount, language } = props;
  //@ts-ignore
  const payments = new tiptop.Widget({
    language: language,
    email: "",
    applePaySupport: true,
    googlePaySupport: true,
    yandexPaySupport: false,
    tinkoffPaySupport: false,
    tinkoffInstallmentSupport: false,
    sbpSupport: false,
  });

  try {
    const widgetResult = await payments.pay("charge", {
      publicId: PublicId,
      description: `Подписка Survey Suite - ${tariffName}`,
      amount: amount,
      currency: currency,
      accountId: userId,
      invoiceId: invoiceId,
      data: {
        userId: userId,
        subscriptionId: subscriptionId,
        planId: planId,
      },
      email: "",
      skin: "classic",
      requireEmail: false,
    });
    console.log("result: ", widgetResult);
    return Promise.resolve();
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};
